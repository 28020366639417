.custom-footer-links {
  display: inline-block;
}

.Toastify__toast-container {
  width: 80%;
}

.new-login-ui .welcome-login {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  color: rgb(250, 239, 239);
  font-size: 30px;
  font-weight: bold;
  padding-top: 20px;
  padding-bottom: 5px;
}

.logo-banner {
  background: linear-gradient(#1e3e72, rgb(42, 1, 72));
  color: white;
  border-radius: 1%;
}

.transparent-btn {
  position: absolute;
  transform: translateY(-7.5rem) translateX(15.6rem);
  background-color: transparent;
  border: none;
  color: inherit;
  font-size: 1.5rem;
  cursor: pointer;
}

page-container {
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  background-size: contain;
  background-position: center;
}

/* Allow scrolling but hide the scrollbar */
body {
  overflow-x: hidden; /* Hide horizontal scrollbar */
  overflow-y: scroll; /* Allow vertical scrolling */
}

/* Hide the scrollbar for WebKit-based browsers (e.g., Chrome, Safari) */
body::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* Optional: Hide scrollbar track */
}
