html {
  height: 100%;
}

.shell-login {
  -webkit-background-size: cover !important;
  -moz-background-size: cover !important;
  -o-background-size: cover !important;
  background-size: cover !important;
  z-index: 0;
}

.brand {
  position: absolute;
  bottom: 20px;
  left: 20px;
}

#login-wrapper {
  width: 450px;
  margin: 4em auto;
  padding: 3em 2em 2em 2em;
  background: #fafafa;
  border: 1px solid #ebebeb;
  box-shadow:
    rgba(0, 0, 0, 0.14902) 0px 1px 1px 0px,
    rgba(0, 0, 0, 0.09804) 0px 1px 2px 0px;
}

.login-footer {
  background-color: rgba(0, 0, 0, 0);
}

legend img {
  width: 100%;
  margin-bottom: 20px;
}

.Toastify__toast-body {
  white-space: pre-line;
}

.new-login-ui {
  max-width: 550px;
  background: #eaf3fb;
  text-align: center;
  top: 100px;
  box-shadow: 0 3px 3px #434242;
  position: relative;
  border-radius: 1%;
}

.new-login-ui .welcome {
  font-size: 1.5em;
  font-weight: bold;
  padding-top: 20px;
  padding-bottom: 20px;
}

.new-login-ui .welcome-msg {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  color: rgb(250, 239, 239);
  padding-bottom: 20px;
  font-size: 18px;
}

.new-login-ui .inner-area {
  color: #4f4f4f;
  padding-top: 40px;
  padding-bottom: 100px;
}

.new-login-ui .login-select-msg {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  margin-bottom: 20px;
  font-size: 20px;
}

.new-login-ui .login-method {
  width: 250px;
  margin: 0 auto 20px auto;
  color: #fff;
  cursor: pointer;
}

.new-login-ui .login-method:focus {
  outline: none;
  border: 0;
}

.new-login-ui .login-method {
  transition: 0.3s box-shadow;
}

.new-login-ui .icon img {
  width: 25px;
  margin-top: 50%;
  transform: translateY(-50%);
  vertical-align: middle;
  margin-left: 7px;
}

.login-method {
  margin-bottom: 30px;
  display: inline-block;
  border-radius: 5px;
}

.login-method .name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.login-method:hover {
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.5);
}

.new-login-ui .icon {
  width: 38px;
  height: 38px;
  float: left;
  background: inherit;
  border-radius: 5px;
}

.new-login-ui .name {
  float: left;
  width: calc(100% - 39px - 15px);
  line-height: 38px;
  font-size: 0.9em;
  height: 38px;
  text-align: left;
  padding-left: 15px;
  font-weight: bold;
  background: inherit;
}

.panel-banner {
  display: inline-block;
  width: 50%;
  margin: 0 auto 30px auto;
  cursor: inherit;
  padding-top: 20px;
}

.default-footerlinks {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.modal-wrap {
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.85);
  transition: 0.6s all;
}

.modal-wrap.ng-hide {
  opacity: 0;
}

@media only screen and (min-width: 500px) {
  .disclaimer-modal {
    top: 10%;
    position: relative;
    background: #fff;
    padding: 1.8em;
    padding-bottom: 60px;
    font-size: 1.2em;
    line-height: 1.7;
    margin: auto;
    width: calc(100% - 200px);
    min-width: 300px;
    max-width: 700px;
    max-height: 70%;
    height: 60%;
  }
}

@media only screen and (max-width: 500px) {
  .disclaimer-modal {
    top: 10%;
    position: relative;
    background: #fff;
    padding: 1.8em;
    padding-bottom: 60px;
    font-size: 1.2em;
    line-height: 1.7;
    max-height: 70%;
    height: 60%;
  }
}

.disclaimer-modal,
.accept-button {
  border-radius: 4px;
}

.disclaimer-modal .disclaimer-content {
  border: grey 1px;
  background: rgb(230, 230, 230);
  padding: 10px;
  overflow: auto;
  height: 90%;
}

.accept-button {
  background: green;
  color: white;
  padding: 1%;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  transition: 0.3s all;
  font-size: 12px;
  text-align: center;
  float: right;
  position: relative;
  top: 5%;
}

.accept-button:hover {
  background: darkgreen;
  box-shadow: 0px 4px 6px 1px rgba(109, 109, 109, 0.59);
}

.incommon-typeahead {
  width: 250px;
  margin: 0 auto 30px;
}

.incommon-typeahead md-autocomplete {
  min-width: 90px !important;
}

.input-group > .rbt {
  flex: 1;
  z-index: 5;
}

.idp-logo {
  margin-right: 10px;
  width: 20px;
}

.incommon-text {
  margin-bottom: 15%;
  font-size: 14px;
}

.remembered-org {
  cursor: pointer;
  margin-top: 10px;
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.remembered-org-logo {
  width: 20%;
  height: 20%;
  margin: auto;
  padding-top: 5px;
}

.remembered-org-text {
  padding-top: 10px;
  margin: auto;
  padding-left: 10px;
  text-align: left;
  font-size: 15px;
  font-weight: bold;
  color: rgb(102, 102, 102);
}

/* **********typica-login.css************ */
/* ##########  */
/*   $GLOBAL   */
/* ##########  */

html {
  height: 100%;
}

.shell-login {
  -webkit-background-size: cover !important;
  -moz-background-size: cover !important;
  -o-background-size: cover !important;
  background-size: cover !important;
  z-index: 0;
}

/* ########### */
/* $NAVIGATION */
/* ########### */

.navbar .brand {
  margin-top: 20px;
  padding-bottom: 8px;
  max-height: 40px;
  width: auto;
}

.navbar .navbar-inner {
  height: 80px;
  /* background: white; */
  filter: none;
  padding: 10px;
}

.navbar .nav {
  margin-top: 20px;
}

/* ####### */
/* $LOGIN  */
/* ####### */

#login-wrapper {
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  border-radius: 15px;
  -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
  box-sizing: border-box;
  padding: 15px;
  width: 450px;
  background: white;
  text-align: center;
}

/* ####### */
/* $LOGIN FORM */
/* ####### */

legend {
  margin-bottom: 0px;
}

.login-form legend {
  /* margin-top: 5px; */
  /* margin-bottom: 30px; */
  padding-bottom: 25px;
}

.login-form legend img {
  width: 85%;
  height: auto;
}

.login-form .body {
  padding-bottom: 30px;
  border-bottom: 1px solid #eeeeee;
}

.login-form .footer {
  margin-top: 20px;
}

.login-form .footer .checkbox.inline {
  display: inline;
}

.login-form .footer .checkbox.inline input[type="checkbox"] {
  float: none;
  margin-top: -4px;
}

.login-form .footer .btn {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  margin-left: 15px;
  padding: 7px 25px;
  background-image: none;
}

/* ######## */
/* LOGIN Screen provider divider line
/*----------or-----------/
/* ######## */
.divider-line {
  line-height: 0.5;
  text-align: center;
}

.divider-line span {
  display: inline-block;
  position: relative;
  color: #ffffff;
}

.divider-line span:before,
.divider-line span:after {
  content: "";
  position: absolute;
  height: 5px;
  border-bottom: 1px solid white;
  top: 0;
  width: 100px;
}

.divider-line span:before {
  right: 100%;
  margin-right: 15px;
}

.divider-line span:after {
  left: 100%;
  margin-left: 15px;
}

/* ######## */
/* $QUERIES */
/* ######## */

/* Large Devices, Wide Screens */

@media only screen and (min-width: 1200px) {
  .container.login {
    width: 100%;
  }

  .brand img {
    height: auto;
    max-width: 30%;
    vertical-align: middle;
    border: 0;
    padding: 15px 0;
  }

  .navbar {
    height: auto;
    margin-bottom: 0;
  }

  .navbar .brand {
    margin-top: 0;
  }

  .navbar .navbar-inner {
    height: auto;
    padding: 20px;
  }

  .navbar .nav > li > a:hover {
    color: white;
  }

  .login-form legend {
    /* margin-top: 5px; */
    /* margin-bottom: 30px; */
    /* padding-bottom: 25px; */
  }

  .login-form legend img {
    width: 85%;
    height: auto;
  }

  .login-form .body {
    padding-bottom: 30px;
    border-bottom: 1px solid #eeeeee;
  }

  .login-form .footer {
    margin-top: 20px;
  }

  #login-wrapper {
    position: relative;
    top: 0;
    /* margin-top: 25px; */
  }

  footer.white {
    padding: 20px;
    height: 50px;
    background: white;
    text-align: right;
  }

  .footer .brand img {
    height: auto;
    max-width: 40%;
    vertical-align: middle;
    border: 0;
  }
}

/* Large Devices, Wide Screens */

@media only screen and (max-width: 1200px) {
  .container.login {
    width: 1200px;
  }

  .brand img {
    height: auto;
    max-width: 30%;
    vertical-align: middle;
    border: 0;
    padding: 15px 0;
  }

  .navbar {
    height: auto;
    margin-bottom: 0;
  }

  .navbar .brand {
    margin-top: 0;
  }

  .navbar .navbar-inner {
    height: auto;
    padding: 20px;
  }

  .navbar .nav > li > a:hover {
    color: white;
  }

  .login-form legend {
    /* margin-top: 5px; */
    /* margin-bottom: 30px; */
    /* padding-bottom: 25px; */
  }

  .login-form legend img {
    width: 85%;
    height: auto;
  }

  .login-form .body {
    padding-bottom: 30px;
    border-bottom: 1px solid #eeeeee;
  }

  .login-form .footer {
    margin-top: 20px;
  }

  #login-wrapper {
    position: relative;
    top: 0;
    margin-top: 25px;
  }

  footer.white {
    padding: 20px;
    height: 50px;
    background: white;
    text-align: right;
  }

  .footer .brand img {
    height: auto;
    max-width: 40%;
    vertical-align: middle;
    border: 0;
  }
}

/* Medium Devices, Desktops */

@media only screen and (max-width: 992px) {
  .container.login {
    width: 992px;
  }

  .brand img {
    height: auto;
    max-width: 35%;
    vertical-align: middle;
    border: 0;
  }

  .navbar {
    height: auto;
    margin-bottom: 0;
  }

  .navbar .brand {
    margin-top: 0;
  }

  .navbar .navbar-inner {
    height: auto;
  }

  .navbar .nav > li > a:hover {
    color: white;
  }

  .login-form legend {
    /* margin-top: 5px; */
    /* margin-bottom: 30px; */
    /* padding-bottom: 25px; */
  }

  .login-form legend img {
    width: 85%;
    height: auto;
  }

  .login-form .body {
    padding-bottom: 30px;
    border-bottom: 1px solid #eeeeee;
  }

  .login-form .footer {
    margin-top: 20px;
  }

  #login-wrapper {
    position: relative;
    top: 0;
    margin-top: 25px;
  }

  footer.white {
    padding: 20px;
    height: 50px;
    background: white;
    text-align: right;
  }

  .footer .brand img {
    height: auto;
    max-width: 40%;
    vertical-align: middle;
    border: 0;
  }
}

/* Small Devices, Tablets */

@media only screen and (max-width: 768px) {
  .container.login {
    width: 900px;
  }

  .brand img {
    height: auto;
    max-width: 35%;
    vertical-align: middle;
    border: 0;
  }

  .navbar {
    height: auto;
    margin-bottom: 0;
  }

  .navbar .brand {
    margin-top: 0;
  }

  .navbar .navbar-inner {
    height: auto;
  }

  .navbar .nav > li > a:hover {
    color: white;
  }

  .login-form legend {
    /* margin-top: 5px; */
    /* margin-bottom: 30px; */
    /* padding-bottom: 25px; */
  }

  .login-form legend img {
    width: 85%;
    height: auto;
  }

  .login-form .body {
    padding-bottom: 30px;
    border-bottom: 1px solid #eeeeee;
  }

  .login-form .footer {
    margin-top: 20px;
  }

  #login-wrapper {
    position: relative;
    top: 0;
    /* margin-top: 25px; */
    width: 65%;
  }

  footer.white {
    padding: 20px;
    height: 50px;
    background: white;
    text-align: right;
  }

  .footer .brand img {
    height: auto;
    max-width: 40%;
    vertical-align: middle;
    border: 0;
  }
}

/* iPhone 6 Plus only --- Landscape */
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
  .container.login {
    width: 900px;
  }

  .brand img {
    height: auto;
    max-width: 35%;
    vertical-align: middle;
    border: 0;
  }

  .navbar {
    height: auto;
    margin-bottom: 0;
  }

  .navbar .brand {
    margin-top: 0;
  }

  .navbar .navbar-inner {
    height: auto;
  }

  .navbar .nav > li > a:hover {
    color: white;
  }

  .login-form legend {
    /* margin-top: 5px; */
    /* margin-bottom: 30px; */
    /* padding-bottom: 25px; */
  }

  .login-form legend img {
    width: 85%;
    height: auto;
  }

  .login-form .body {
    padding-bottom: 30px;
    border-bottom: 1px solid #eeeeee;
  }

  .login-form .footer {
    margin-top: 20px;
  }

  #login-wrapper {
    position: absolute;
    margin-top: 20px;
    width: 48%;
    right: 20px;
  }

  footer.white {
    padding: 20px;
    height: 50px;
    background: white;
    text-align: right;
  }

  .footer .brand img {
    height: auto;
    max-width: 40%;
    vertical-align: middle;
    border: 0;
  }
}

/* Extra Small Devices  */

@media only screen and (max-width: 480px) {
  .container.login {
    width: 479px;
  }

  .brand img {
    height: auto;
    max-width: 65%;
    vertical-align: middle;
    border: 0;
  }

  .navbar {
    height: auto;
    margin-bottom: 0;
  }

  .navbar .brand {
    margin-top: 0;
  }

  .navbar .navbar-inner {
    height: auto;
  }

  .navbar .nav > li > a:hover {
    color: white;
  }

  .login-form legend {
    /* margin-top: 5px; */
    /* margin-bottom: 30px; */
    /* padding-bottom: 25px; */
  }

  .login-form legend img {
    width: 85%;
    height: auto;
  }

  .login-form .body {
    padding-bottom: 30px;
    border-bottom: 1px solid #eeeeee;
  }

  .login-form .footer {
    margin-top: 20px;
  }

  #login-wrapper {
    position: relative;
    top: 0;
    margin-top: 5px;
    width: 75%;
  }

  footer.white {
    padding: 20px;
    height: 50px;
    background: white;
    text-align: right;
  }

  .footer .brand img {
    height: auto;
    max-width: 40%;
    vertical-align: middle;
    border: 0;
  }
}

/* Extra Small Devices, galaxy etc */

@media only screen and (max-width: 320px) {
  .container.login {
    width: 319px;
  }

  .brand img {
    height: auto;
    max-width: 90%;
    vertical-align: middle;
    border: 0;
  }

  .navbar {
    height: auto;
    margin-bottom: 0;
  }

  .navbar .brand {
    margin-top: 0;
  }

  .navbar .navbar-inner {
    height: auto;
  }

  .navbar .nav > li > a:hover {
    color: white;
  }

  .login-form legend {
    margin-top: 5px;
    margin-bottom: 30px;
    padding-bottom: 25px;
  }

  .login-form legend img {
    width: 85%;
    height: auto;
  }

  .login-form .body {
    padding-bottom: 30px;
    border-bottom: 1px solid #eeeeee;
  }

  .login-form .footer {
    margin-top: 20px;
  }

  #login-wrapper {
    position: relative;
    top: 0;
    margin-top: 25px;
    width: 80%;
  }

  footer.white {
    padding: 20px;
    height: 50px;
    background: white;
    text-align: right;
  }

  .footer .brand img {
    height: auto;
    max-width: 40%;
    vertical-align: middle;
    border: 0;
  }
}

@media only screen and (min-width: 320px) and (max-device-width: 667px) and (orientation: landscape) {
  .container.login {
    width: 100%;
  }

  .brand img {
    height: auto;
    max-width: 40%;
    vertical-align: middle;
    border: 0;
  }

  .navbar {
    height: auto;
    margin-bottom: 0;
  }

  .navbar .brand {
    margin-top: 0;
  }

  .navbar .navbar-inner {
    height: auto;
  }

  .navbar .nav > li > a:hover {
    color: white;
  }

  .login-form legend {
    margin-top: 5px;
    margin-bottom: 15px;
    padding-bottom: 25px;
  }

  .login-form legend img {
    width: 85%;
    height: auto;
  }

  .login-form .body {
    padding-bottom: 30px;
    border-bottom: 1px solid #eeeeee;
  }

  .login-form .footer {
    margin-top: 20px;
  }

  #login-wrapper {
    position: absolute;
    margin-top: 17px;
    width: 45%;
    right: 20px;
  }

  footer.white {
    padding: 20px;
    height: 50px;
    background: white;
    text-align: right;
  }

  .footer .brand img {
    height: auto;
    max-width: 40%;
    vertical-align: middle;
    border: 0;
  }
}

.carousel {
  left: 0px;
  top: 0px;
  overflow: hidden;
  margin: 0px;
  padding: 0px;
  height: 100vh;
  width: 100vw;
  z-index: -999998;
  position: absolute !important;
  display: block;
}
.carousel-item img {
  height: 100vh;
  width: 100vw;
  object-fit: cover;
}

.carousel-fade .carousel-inner .item {
  opacity: 0;
  transition-property: opacity;
}

.carousel-fade .carousel-inner .active {
  opacity: 1;
}

.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
  left: 0;
  opacity: 0;
  z-index: 1;
}

.carousel-fade .carousel-inner .next.left,
.carousel-fade .carousel-inner .prev.right {
  opacity: 1;
}

.carousel-fade .carousel-control {
  z-index: 2;
}

/*
  WHAT IS NEW IN 3.3: "Added transforms to improve carousel performance in modern browsers."
  Need to override the 3.3 new styles for modern browsers & apply opacity
*/
@media all and (transform-3d), (-webkit-transform-3d) {
  .carousel-fade .carousel-inner > .item.next,
  .carousel-fade .carousel-inner > .item.active.right {
    opacity: 0;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  .carousel-fade .carousel-inner > .item.prev,
  .carousel-fade .carousel-inner > .item.active.left {
    opacity: 0;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  .carousel-fade .carousel-inner > .item.next.left,
  .carousel-fade .carousel-inner > .item.prev.right,
  .carousel-fade .carousel-inner > .item.active {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
